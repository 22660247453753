import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Link, Text } from '@gs-ux-uitoolkit-react/text';
import { format, parseISO } from 'date-fns';
import React, { useCallback } from 'react';
import { TNewsItem } from '../../types';
import './news-tile.css';

type TNewsTileProps = {
  data: TNewsItem;
};

const formatDateForNewsTile = (dateISOString: string) => {
  return format(parseISO(dateISOString), 'MMMM dd, yyyy');
};

export const RecentNewsTile: React.FC<TNewsTileProps> = ({ data }: TNewsTileProps) => {
  return (
    <div
      className="flex flex-col p-6 border border-gray-020/50 justify-between min-w-[300px]"
      id="news-tile"
    >
      <div className="flex flex-col gap-2">
        <div className="bg-[#50B4D7] px-2 py-1 w-fit flex items-center">
          <Text color="primary" fontSize={'12px'} lineHeight="normal" fontWeight={500}>
            {data.category}
          </Text>
        </div>
        <div>
          <Text
            className="xl:text-[28px] xl:leading-[34px] md:text-[24px] md:leading-[30px] text-[24px] leading-[30px]"
            fontWeight={200}
          >
            {data.headline}
          </Text>
        </div>
        <div>
          <Text color="tertiary" fontSize={'12px'}>
            {formatDateForNewsTile(data.date)}
          </Text>
        </div>
      </div>
      <div>
        <Text typography="body01" className="flex flex-row items-center gap-1">
          Read on{' '}
          <Link
            emphasis="subtle"
            href={data.articleLink}
            className="visited:text-[#50B4D7]"
            target="_blank"
            style={{ color: '#50B4D7' }}
          >
            {data.mediaOutlet}
          </Link>{' '}
          <Icon
            name="open-in-new"
            type="filled"
            style={{ color: 'white', fontSize: '14px' }}
          ></Icon>
        </Text>
      </div>
    </div>
  );
};

export const AllNewsTile: React.FC<TNewsTileProps> = ({ data }: TNewsTileProps) => {
  return (
    <div
      className={`flex flex-col gap-2 p-6 border-t border-gray-020/50 justify-between`}
      id="news-tile-long"
    >
      <div className="flex flex-row gap-4 items-center">
        <div className="bg-[#50B4D7] px-2 py-1 w-fit flex items-center">
          <Text color="primary" fontSize={'12px'} lineHeight="normal" fontWeight={500}>
            {data.category}
          </Text>
        </div>
        <Text color="tertiary" fontSize={'12px'}>
          {formatDateForNewsTile(data.date)}
        </Text>
      </div>
      <Text
        fontWeight={200}
        className="xl:text-[28px] xl:leading-[34px] md:text-[24px] md:leading-[30px] text-[24px] leading-[30px]"
      >
        {data.headline}
      </Text>
      <div>
        <Text typography="body01" className="flex flex-row items-center gap-1">
          Read on{' '}
          <Link
            emphasis="subtle"
            href={data.articleLink}
            className="visited:text-[#50B4D7]"
            style={{ color: '#50B4D7' }}
            target="_blank"
          >
            {data.mediaOutlet}
          </Link>{' '}
          <Icon
            name="open-in-new"
            type="filled"
            style={{ color: 'white', fontSize: '14px' }}
          ></Icon>
        </Text>
      </div>
    </div>
  );
};

export const FeaturedNewsTile: React.FC<TNewsTileProps> = ({ data }: TNewsTileProps) => {
  const stopPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  return (
    <div className="flex flex-col gap-2" id="news-tile-featured">
      <div className="bg-[#50B4D7] px-2 py-1 w-fit flex items-center">
        <Text color="primary" fontSize={'12px'} lineHeight="normal" fontWeight={500}>
          {data.category}
        </Text>
      </div>
      <Text
        className="2xl:text-5xl 2xl:leading-[54px] xl:text-[40px] xl:leading-[46px] text-[28px] leading-[34px]"
        fontWeight={200}
      >
        {data.headline}
      </Text>
      <Text
        color="tertiary"
        className="xl:text-2xl xl:leading-9  text-[16px] leading-[24px] "
        fontWeight={200}
      >
        {data.summaryCopy}
      </Text>

      <div>
        <Text typography="body01" className="flex flex-row items-center gap-1">
          {data.category === 'Video' ? 'Watch' : 'Read'} on{' '}
          <Link
            emphasis="subtle"
            href={data.articleLink}
            className="visited:text-[#50B4D7]"
            style={{ color: '#50B4D7' }}
            target="_blank"
            onClick={stopPropagation}
          >
            {data.mediaOutlet}
          </Link>{' '}
          <Icon
            name="open-in-new"
            type="filled"
            style={{ color: 'white', fontSize: '14px' }}
          ></Icon>
        </Text>
      </div>
    </div>
  );
};
